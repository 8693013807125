import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getOneUser } from "../../services/User/UserService";
import dummy from "../../images/Profile_avatar_placeholder_large.png";
import moment from "moment";
import Spinner from "../loader/Spinner";
import Reason from "../modals/Reason";
import { approveUser } from "../../services/Requests/RequestServices";
function UserProfile(props) {
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const [loader, setLoader] = useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [alldata, setAlldata] = useState({});
  const [approval, setApproval] = useState("");
  const [show, setShow] = useState(false);
  const [id, setId] = useState(false);
  const [images, setImages] = useState([]);
  const userId = props.userId;
  const imgBaseUrl = "https://hart-app.s3.amazonaws.com/";
  if (userId == "") {
    props.history.push("/users-management");
  }

  function handlefetch() {
    setLoader(true);
    getOneUser(userId)
      .then((response) => {
        setAlldata(response.data.data);
        setProfilePic(response.data.data.profilePic);
        setApproval(response.data.data.approval.status);
        setImages(response.data.data.profilePics);
        setLoader(false);

        console.log(response.data.data, " table data ");
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("token");
          props.history.push("/");
        }
      });
  }
  function onApprove(userId, approve) {
    console.log(userId, approve, "user id");
    approveUser(userId, approve)
      .then((response) => {
        notifyTopRight("User Approved Successfully.");
        console.log(response, "aaaaa");
        props.history.push("/users-management");
      })
      .catch((error) => {
        notifyError(error.response.data.message);

        if (error.response.data.statusCode === 401) {
          localStorage.clear("token");
          props.history.push("/");
        }
      });
  }
  function showModal(id) {
    setId(id);
    setShow(true);
  }
  useEffect(() => {
    handlefetch();
  }, []);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="page-titles">
        <h4>User Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="users-management">Users</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>User Details</Link>
          </li>
        </ol>
      </div>
      <Card style={{ width: "70%" }}>
        <Card.Header>
          <div
            className="d-flex justify-content-between"
            style={{ gap: "1rem" }}
          >
            <div>
              <img
                src={
                  profilePic
                    ? imgBaseUrl + profilePic
                    : dummy
                }
                width={110}
                height={110}
                className="text-center border-radius-50"
              />
              <div
                className="d-flex justify-content-start pt-3 mb-3"
                style={{ gap: "1rem" }}
              >
                {images?.map((item, i) => (
                  <img
                    key={i}
                    src={item ? imgBaseUrl + item : dummy}
                    width={80}
                    height={80}
                    className=" border-radius-50"
                  />
                ))}

                {/* <img
                  src={
                    imgBaseUrl + item.profilePics[0]
                      ? imgBaseUrl + item.profilePics[0]
                      : dummy
                  }
                  width={80}
                  height={80}
                  className=" border-radius-50"
                />
                <img
                  src={
                    imgBaseUrl + item.profilePics[0]
                      ? imgBaseUrl + item.profilePics[0]
                      : dummy
                  }
                  width={80}
                  height={80}
                  className=" border-radius-50"
                /> */}
              </div>
            </div>
            <div className="text-left">
              <h3 className="text-color">
                {alldata?.name},<span className="fs-16">{alldata?.gender}</span>
              </h3>
              <p>{alldata?.phoneNumber}</p>
              <p className="mt-0">{alldata?.address}</p>

            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="text-black">
            {alldata?.dob && (
              <div className="d-flex justify-content-between align-items-center pb-3">
                <h5>Date Of Birth</h5>
                <p className="btn-style btn-danger light px-4 py-2">
                  {moment(alldata?.dob).format("DD/MM/YYYY")}
                </p>
              </div>
            )}
          {alldata?.age && (
              <div className="d-flex justify-content-between align-items-center pb-3">
                <h5>Age</h5>
                <p className="btn-style btn-danger light px-4 py-2">{alldata?.age}</p>
              </div>
            )}
             {alldata?.interestedIn && (
              <div className="d-flex justify-content-between align-items-center pb-3">
                <h5>Interested In</h5>
                <p className="btn-style btn-danger light px-4 py-2">
                  {alldata?.interestedIn}
                </p>
              </div>
            )}
             {alldata?.pronoun && (
              <div className="d-flex justify-content-between align-items-center pb-3">
                <h5>Pronoun</h5>
                <p className="btn-style btn-danger light px-4 py-2">
                  {alldata?.pronoun}
                </p>
              </div>
            )}
            {alldata?.sexualOrientation && (
              <div className="d-flex justify-content-between align-items-center pb-3">
                <h5>Sexual Orientation</h5>
                <p className="btn-style btn-danger light px-4 py-2">
                  {alldata?.sexualOrientation}
                </p>
              </div>
            )}
           {alldata?.subscriptionPackage && (
              <div className="d-flex justify-content-between align-items-center pb-3">
                <h5>Subscription Package</h5>
                <p className="btn-style btn-danger light px-4 py-2">
                  {alldata?.subscriptionPackage.status}
                </p>
              </div>
            )} 
          </div>
          {approval === "Accepted" ? (
            ""
          ) : (
            <div className="text-right">
              <Button
                type="button"
                className="btn-secondary py-2 px-4 mr-4"
                onClick={() => showModal(userId)}
              >
                Reject
              </Button>
              <Button
                className="bg-color py-2 px-4 "
                type="button"
                onClick={() => {
                  onApprove(userId, true);
                }}
              >
                Approve
              </Button>
            </div>
          )}
        </Card.Body>
      </Card>
      <Reason
        show={show}
        id={id}
        table={handlefetch}
        onHide={() => setShow(false)}
      />
      {loader && <Spinner />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.userId.userId,
  };
};
export default connect(mapStateToProps)(UserProfile);
