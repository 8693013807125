import instance from "../Instance";
import { GET_SUBSCRIBERS } from "./ApiEndpoints";

export function getSubscribers(currentPage, limit) {
  const data = localStorage.getItem("token");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_SUBSCRIBERS + `?page=${currentPage}&limit=${limit}`, {
    headers: myHeaders,
  });
}
export function getAllSubscribers(currentPage) {
    const data = localStorage.getItem("token");
    const myHeaders = {
      Authorization: `Bearer ${data}`,
    };
    return instance.get(GET_SUBSCRIBERS + `?page=${currentPage}`, {
      headers: myHeaders,
    });
  }
