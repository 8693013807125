import instance from "../Instance";
import { APPROVE_USER, GET_REQUESTS, GET_VERIFICATION_REQUESTS } from "./RequestsEndpoints";


export function getRequests(currentPage, limit) {
    const data = localStorage.getItem("token");
    const myHeaders = {
      Authorization: `Bearer ${data}`,
    };
    return instance.get(
        GET_REQUESTS + `?page=${currentPage}&limit=${limit}`,
      {
        headers: myHeaders,
      }
    );
  }

export function approveUser(userId, approve) {
    const postData = {
      userId,
      approve,
    };
    const data = localStorage.getItem("token");
    const myHeaders = {
      Authorization: `Bearer ${data}`,
    };
    return instance.post(APPROVE_USER, postData, {
      headers: myHeaders,
    });
  }
  export function rejectUser(userId, approve, reason) {
    const postData = {
      userId,
      approve,
      reason,
    };
    const data = localStorage.getItem("token");
    const myHeaders = {
      Authorization: `Bearer ${data}`,
    };
    return instance.post(APPROVE_USER, postData, {
      headers: myHeaders,
    });
  }
  export function getVerificationRequests(currentPage, limit) {
    const data = localStorage.getItem("token");
    const myHeaders = {
      Authorization: `Bearer ${data}`,
    };
    return instance.get(
      GET_VERIFICATION_REQUESTS + `?page=${currentPage}&limit=${limit}`,
      {
        headers: myHeaders,
      }
    );
  }


  