import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { rejectUser } from "../../services/Requests/RequestServices";
import Spinner from "../loader/Spinner";

export default function Reason({ props, show, id, table, onHide }) {
  const [loader, setLoader] = useState(false);
  const [reason, setReason] = useState("");
  let errorsObj = { reason: "" };
  const [errors, setErrors] = useState(errorsObj);
  console.log(reason, "reason");
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  function onReject(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    const pattern = /^[^\s]+$/; // Regular expression to match any non-empty string
    if (!pattern.test(reason)) {
      errorObj.reason = "This field is requird!";
      error = true;
    }
    if (reason === "") {
      errorObj.reason = "This field is requird!";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    console.log(id);
    setLoader(true);
    rejectUser(id, false, reason)
      .then((response) => {
        notifyTopRight("Rejected successfully.");
        setLoader(false);
        table();
        onHide();

        console.log(response);
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        setLoader(false);
        notifyError(error.response.data.message);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("token");
          props.history.push("/");
        }
      });
  }
  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">
                      Please provide a reason
                    </label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        placeholder="Write something here.."
                        pattern="[a-zA-Z][a-zA-Z0-9\s]*"
                        required
                      />
                      <span className="validation-text"></span>
                      {errors.reason && (
                        <div className="text-danger fs-12">{errors.reason}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={onReject}
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => onHide()}
                className="btn btn-danger"
              >
                {" "}
                <i className="flaticon-delete-1"></i> Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
      {loader && <Spinner />}
    </Modal>
  );
}
