import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Link } from "react-router-dom";
import { Dropdown, Card, Table, Col } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Spinner from "../loader/Spinner";
import swal from "sweetalert";
import moment from "moment";
import {
  blockUser,
  deleteUser,
  getAllUsers,
} from "../../services/User/UserService";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../common/Pagination";
import { connect, useDispatch } from "react-redux";
import { setUserIdAction } from "../../store/actions/UserIdAction";
import dummy from "../../images/download (1).png";
function Users(props) {
  const dispatch = useDispatch();
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [listLenght, setListLenght] = useState("");
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [apiError, setApiError] = useState("");
  const [search, setSearch] = useState("");
  const limit = 10;
  // const currentPage = props.currentData;
  const imgBaseUrl = "https://hart-app.s3.amazonaws.com/";

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function getTableData() {
    setLoader(true);
    getAllUsers(currentPage, limit, search)
      .then((response) => {
        setUsers(response.data.data.users);
        const total = response.data.data.count;
        setListLenght(response.data.data.count);
        setLoader(false);
        setPageCount(Math.ceil(total / limit));
        console.log(Math.ceil(total / limit), " page count");
        console.log(response.data.data.users, " table data ");
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 401) {
          localStorage.clear("token");
          props.history.push("/");
        }
      });
  }

  function onDelete(userId) {
    console.log(userId, "user id");
    deleteUser(userId)
      .then((response) => {
        // swal("User Blocked Successfully.", { icon: "success" });
        notifyTopRight("User Deleted Successfully.");
        getTableData();
      })
      .catch((error) => {
        notifyError(error.response.data.message);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("token");
          props.history.push("/");
        }
      });
  }
  function onUnblock(userId,toggle) {
    
    blockUser(userId,toggle)
      .then((response) => {
        notifyTopRight("User Unblocked Successfully.");
    
        getTableData();
      })
      .catch((error) => {
     

        notifyError(error.response.data.message);
        if (error.response.data.statusCode === 400) {
          localStorage.clear("userDetails");
          props.history.push("/login");
        }
      });
  }
  function onBlock(userId,toggle) {
    console.log(userId,toggle, "user id 11111");
    blockUser(userId,toggle)
      .then((response) => {
        notifyTopRight("User Blocked Successfully.");
        getTableData();
      })
      .catch((error) => {
        notifyError(error.response.data.message);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("token");
          props.history.push("/");
        }
      });
  }

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  useEffect(() => {
    if (search.length > 0) {
      getTableData();
    } else {
      getTableData();
    }
  }, [currentPage, search]);
  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Users List" motherMenu="Users" />
      <Col>
        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center">
              <div className="col-8" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      style={{
                        paddingBottom: "25px",
                        paddingTop: "25px",
                        borderRadius: "10px",
                        fontSize: "14px",
                      }}
                      type="text"
                      className="form-control float-right border-0"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={getTableData}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>IMAGE</strong>
                  </th>
                  <th>
                    <strong>NAME</strong>
                  </th>

                  <th>
                    <strong>DATE OF BIRTH</strong>
                  </th>
                  <th>
                    <strong>PHONE NUMBER</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item) => (
                  <tr>
                    <td>
                      <img
                        src={item.profilePic?imgBaseUrl + item.profilePic:dummy}
                        width={70}
                        height={70}
                      />{" "}
                    </td>
                    <td
                      onClick={() => (
                        dispatch(setUserIdAction(item._id)),
                        props.history.push("/user-profile")
                      )}
                      style={{cursor:"pointer"}}
                    >
                      {item.name}
                    </td>

                    <td>{moment(item?.dob).format("DD/MM/YYYY")} </td>
                    <td>{item.phoneNumber}</td>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="danger light"
                          className="light sharp btn btn-info i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => (
                              dispatch(setUserIdAction(item._id)),
                              props.history.push("/verify-details")
                            )}
                          >
                            Verify Details
                          </Dropdown.Item>
                          {item.isBlocked ? (
                            <Dropdown.Item onClick={() => onUnblock(item._id,0)}>
                              {" "}
                              Enable
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item onClick={() => onBlock(item._id,1)}>
                              Disable
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item onClick={() => onDelete(item._id)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            <div className="card-footer clearfix">
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLenght > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLenght}{" "}
                  of {listLenght} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    currentData: state.currentData.currentData,
  };
};
export default connect(mapStateToProps)(Users);
