import React from "react";
import logo from "../../../images/Logo 2.svg";
export default function Header() {
  return (
    <div className="container py-3 navbar-fixed">
      <nav className="navbar">
        <div className="container d-flex justify-content-between align-items-center text-white">
          <div>
            <img src={logo} className="img-fluid logo-img" alt="logo"/>
          </div>
          <ul className="navbar-nav d-flex flex-row align-items-center " style={{gap:"1rem"}}>
            <li
              className="nav-item text-dark"
              onClick={() =>
                window.open("https://blogs.hartdating.com/", "_blank")
              }
            >
              Blog               
            </li>
            <li
              className="nav-item text-dark"
              id="join-nav-button"
              style={{ cursor: "pointer" }}
              onClick={() => {
                // setmodelState(true);
              }}
            >
              Join
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}
