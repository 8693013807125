import React, { useEffect, useState } from "react";
import {
  approveUser,
  getRequests,
  getVerificationRequests,
} from "../../services/Requests/RequestServices";
import Spinner from "../loader/Spinner";
import moment from "moment";
import Pagination from "../common/Pagination";
import { Button, Card, Col, Nav, Tab } from "react-bootstrap";
import Swal from "sweetalert2";
import PageTitle from "../layouts/PageTitle";
import { toast, ToastContainer } from "react-toastify";
import Reason from "../modals/Reason";
import dummy from "../../images/Profile_avatar_placeholder_large.png";
import { useDispatch } from "react-redux";
import { setUserIdAction } from "../../store/actions/UserIdAction";
export default function RequestsManagement(props) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [requests, setRequests] = useState([]);
  const [verificationRequests, setVerificationRequests] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [newPageCount, setNewPageCount] = useState(0);
  const [newCurrentPage, setNewCurrentPage] = useState(0);
  const [listLenght, setListLenght] = useState("");
  const [verificationListLenght, setLerificationListLenght] = useState("");
  // console.log(pageCount, "hello lenght ji");
  const [show, setShow] = useState(false);
  const [id, setId] = useState(false);
  const imgBaseUrl = "https://hart-app.s3.amazonaws.com/";
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const limit = 10;
  function getTableData() {
    setLoader(true);
    getRequests(currentPage, limit)
      .then((response) => {
        setRequests(response.data.data.users);
        const total = response.data.data.count;
        setListLenght(response.data.data.count);

        setLoader(false);
        setPageCount(Math.ceil(total / limit));

        console.log(response.data, " table data ");
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 401) {
          localStorage.clear("token");
          props.history.push("/");
        }
      });
  }

  function onApprove(userId, approve) {
    console.log(userId, approve, "user id");
    approveUser(userId, approve)
      .then((response) => {
        console.log(response, "aaaaa");
        // Swal("User Approved Successfully.", { icon: "success" });
        notifyTopRight("User Approved Successfully.");
        getTableData();
      })
      .catch((error) => {
        console.log(error.response, "error");
        notifyError(error.response.data.message);

        if (error.response.data.statusCode === 401) {
          localStorage.clear("token");
          props.history.push("/");
        }
      });
  }
  function showModal(id) {
    setId(id);
    setShow(true);
  }

  function getRequestData() {
    setLoader(true);

    getVerificationRequests(newCurrentPage, limit)
      .then((response) => {
        setVerificationRequests(response.data.data.users);
        const total = response.data.data.count;
        setLerificationListLenght(response.data.data.count);
        setLoader(false);
        setNewPageCount(Math.ceil(total / limit));

        console.log(response.data, " table data verifivation");
      })
      .catch((error) => {
        setLoader(false);

        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 401) {
          localStorage.clear("token");
          props.history.push("/");
        }
      });
  }

  function profileImages (item){
    if(item.profilePics === ""){
 return 
    }
  }
  useEffect(() => {
    getTableData();
  }, [currentPage]);
  useEffect(() => {
    getRequestData();
  }, [newCurrentPage]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Requests" motherMenu="Requests Management" />
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="home">
          <Nav as="ul" className="nav-tabs">
            <Nav.Item as="li">
              <Nav.Link eventKey="home">User Approval Requests</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="second">
                Document Verification Requests
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="home">
           
                <Col>
                  {requests?.map((item, i) => (
                    <Card key={i}>
                      <Card.Body>
                        <div>
                          <h3 className="text-black">New User Resisterd!</h3>
                          <p className="fs-14">
                            Description: Resisterd on{" "}
                            {moment(item.createdAt).format("DD/MM/YYYY")}.
                          </p>
                          <div className="d-flex justify-content-between align-items-center fs-16 mt-3">
                            <div
                              className="d-flex align-items-center"
                              onClick={() => (
                                dispatch(setUserIdAction(item._id)),
                                props.history.push("/user-profile")
                              )}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={
                                  item.profilePics[0]
                                    ? imgBaseUrl + item.profilePics[0]
                                    : dummy
                                }
                                alt=""
                                width={80}
                                height={80}
                                style={{ borderRadius: "50%" }}
                              />
                              <div className="pl-5">
                                <p className="">
                                  {item.gender
                                    ? item.name + ", " + item.gender
                                    : item.name}
                                </p>

                                <p className="">{item.phoneNumber}</p>
                              </div>
                            </div>
                            <div className="">
                              <Button
                                type="button"
                                className="btn-secondary py-2 px-4 mr-4"
                                onClick={() => showModal(item._id)}
                              >
                                Reject
                              </Button>
                              <Button
                                className="bg-color py-2 px-4 "
                                type="button"
                                onClick={() => {
                                  onApprove(item._id, true);
                                }}
                              >
                                Approve
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  ))}
                  {requests?.length === 0 && !loader ? (
                    <div className="justify-content-center d-flex my-5 ">
                      Sorry, Requests not found!
                    </div>
                  ) : (
                    ""
                  )}
                  {listLenght > 0 && (
                    <div className="card-footer clearfix">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Showing {currentPage * limit + 1} to{" "}
                          {listLenght > (currentPage + 1) * limit
                            ? (currentPage + 1) * limit
                            : listLenght}{" "}
                          of {listLenght} entries
                        </div>
                        <Pagination
                          pageCount={pageCount}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  )}
                </Col>
              
            </Tab.Pane>
          </Tab.Content>
          <Tab.Content>
            <Tab.Pane eventKey="second">
              <Col>
                {verificationRequests?.map((item, i) => (
                  <Card key={i}>
                    <Card.Body>
                      <div>
                        <div className="d-flex justify-content-between align-items-center fs-16 mt-3">
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                item?.profilePics?.length >0
                                  ? imgBaseUrl + item?.profilePics[0]
                                  : dummy
                              }
                              alt=""
                              width={80}
                              height={80}
                              style={{ borderRadius: "50%" }}
                            />
                            <div className="pl-5">
                              <p className="">
                                {item.gender
                                  ? item.name + ", " + item.gender
                                  : item.name}
                              </p>

                              <p className="">{item.phoneNumber}</p>
                            </div>
                          </div>
                          <div className="">
                            <Button
                              className="bg-color py-2 px-4 "
                              type="button"
                              onClick={() => (
                                dispatch(setUserIdAction(item._id)),
                                props.history.push("/verify-details")
                              )}
                            >
                              View Details
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ))}
                {verificationRequests?.length === 0 && !loader ? (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Requests not found!
                  </div>
                ) : (
                  ""
                )}
                {verificationListLenght > 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {newCurrentPage * limit + 1} to{" "}
                        {verificationListLenght > (newCurrentPage + 1) * limit
                          ? (newCurrentPage + 1) * limit
                          : verificationListLenght}{" "}
                        of {verificationListLenght} entries
                      </div>
                      <Pagination
                        pageCount={newPageCount}
                        pageValue={newCurrentPage}
                        setPage={setNewCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>

      <Reason
        show={show}
        id={id}
        table={getTableData}
        onHide={() => setShow(false)}
      />
      {loader && <Spinner />}
    </div>
  );
}
