import { SET_USER_ID_ACTION } from "../actions/UserIdAction";



const initialState = {
    userId: "",
};

export default function UserIdReducer(state = initialState, action) {
    
    if (action.type === SET_USER_ID_ACTION) {
        return {
            ...state,
            userId: action.payload,
        };
    }
    return state;
}