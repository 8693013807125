import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Table, Col, Button, Badge } from "react-bootstrap";
import Spinner from "../loader/Spinner";
import moment from "moment";
import { connect } from "react-redux";
import { getOneUser, verifyUserDetails } from "../../services/User/UserService";
import { toast, ToastContainer } from "react-toastify";
import dummyImg from "../../images/download (1) 2.png"
function UserDetails(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [userName, setUserName] = useState("");
  const [type, setType] = useState("");
  const [response, setResponse] = useState("");
  const userId = props.userId;
  const imgBaseUrl = "https://hart-app.s3.amazonaws.com/";
  if (userId == "") {
    props.history.push("/users-management");
  }
  console.log(type, response, "data");

  function handlefetch() {
    setLoader(true);
    getOneUser(userId)
      .then((response) => {
        setData(response.data.data.profileControls.verifiedItems);
        setUserName(response.data.data.name);
        setLoader(false);

        console.log(response.data.data, " table data ");
      })
      .catch((error) => {
        console.log(error.response.data, "helooooooooo");
        if (error.response.data.statusCode === 404) {
          localStorage.clear("token");
          props.history.push("/login");
        }
      });
  }

  function onVerify(e) {
    setType(e.target.value);
    setResponse("accept");
    if (type && response) {
      verifyDetails();
      console.log(type, response, "data 1");
    }
  }
  function onReject(e) {
    setType(e.target.value);
    setResponse("reject");
    if (type && response) {
      verifyDetails();
      console.log(type, response, "data 1");
    }
  }
  function verifyDetails() {
    console.log(type, response, "data 2");
    // if(type && response){
    setLoader(true);
    verifyUserDetails(userId, type, response)
      .then((response) => {
        notifyTopRight("Verified Successfully.");
        setLoader(false);
        handlefetch();
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        notifyError(error.response.data.data);
      });
    // }
  }

  useEffect(() => {
    handlefetch();
  }, []);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="page-titles">
        <h4>Verify Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="users-management">Users</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Verify Details</Link>
          </li>
        </ol>
      </div>
      <Card>
        <Card.Header>
          <Card.Title>{userName}</Card.Title>
        </Card.Header>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>Picture</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <img
              src={data.pic?.image?imgBaseUrl + data.pic?.image:dummyImg}
              alt="img"
              width={70}
              height={70}
            />
            <div>
              {data.pic?.status == "rejected" ? (
                <Badge variant="danger light">{data.pic?.status}</Badge>
              ) : (
                <Badge variant="success light">{data.pic?.status}</Badge>
              )}
            </div>
            <div>
              <Button
                className="bg-color py-2 px-4 mr-4"
                type="button"
                value="pic"
                onClick={(e) => onVerify(e)}
              >
                Verify
              </Button>

              <Button
                type="button"
                className="btn-secondary py-2 px-4"
                value="pic"
                onClick={(e) => onReject(e)}
              >
                Reject
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>Employment</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <img
              src={data.employment?.image?imgBaseUrl + data.employment?.image:dummyImg}
              alt="img"
              width={70}
              height={70}
            />
            <div>
              <Badge variant="success light">{data.employment?.status}</Badge>
            </div>
            <div>
              <Button
                type="button"
                className="bg-color py-2 px-4 mr-4"
                value={"employment"}
                onClick={(e) => onVerify(e)}
              >
                Verify
              </Button>
              <Button
                type="button"
                className="btn-secondary py-2 px-4"
                value={"employment"}
                onClick={(e) => onReject(e)}
              >
                Reject
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>Degree</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <img
              src={data.degree?.image?imgBaseUrl + data.degree?.image:dummyImg}
              alt="img"
              width={70}
              height={70}
            />
            <div>
              <Badge variant="success light">{data.degree?.status}</Badge>
            </div>
            <div>
              <Button
                type="button"
                className="bg-color py-2 px-4 mr-4"
                value={"degree"}
                onClick={(e) => onVerify(e)}
              >
                Verify
              </Button>
              <Button
                type="button"
                className="btn-secondary py-2 px-4"
                value={"degree"}
                onClick={(e) => onReject(e)}
              >
                Reject
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>Income</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <img
              src={data.income?.image?imgBaseUrl + data.income?.image:dummyImg}
              alt="img"
              width={70}
              height={70}
            />
            <div>
              <Badge variant="success light">{data.income?.status}</Badge>
            </div>
            <div>
              <Button
                type="button"
                className="bg-color py-2 px-4 mr-4"
                value={"income"}
                onClick={(e) => onVerify(e)}
              >
                Verify
              </Button>
              <Button
                type="button"
                className="btn-secondary py-2 px-4"
                value={"income"}
                onClick={(e) => onReject(e)}
              >
                Reject
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>
          <Card.Title>ID</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <img
              src={data.id?.image?imgBaseUrl + data.id?.image:dummyImg}
              alt="img"
              width={70}
              height={70}
            />
            <div>
              <Badge variant="success light">{data.id?.status}</Badge>
            </div>
            <div>
              <Button
                type="button"
                className="bg-color py-2 px-4 mr-4"
                value={"id"}
                onClick={(e) => onVerify(e)}
              >
                Verify
              </Button>
              <Button
                type="button"
                className="btn-secondary py-2 px-4"
                value={"id"}
                onClick={(e) => onReject(e)}
              >
                Reject
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      {loader && <Spinner />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userId: state.userId.userId,
  };
};
export default connect(mapStateToProps)(UserDetails);
