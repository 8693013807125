export const GET_USERS = 'admin/user/list';

export const DELETE_USERS = 'admin/user/delete';

export const BLOCK_USERS = 'admin/user/block';




// user details

export const GET_USER_DETAILS = 'admin/user/detail';

export const VERIFY_USER_DETAILS = 'admin/user/verify';