import instance from "../Instance";
import { DASHBOARD } from "./ApiEndPoints";

export function getDeshboard() {
    const data = localStorage.getItem("token");
    const myHeaders = {
      Authorization: `Bearer ${data}`,
    };
    return instance.get(DASHBOARD, {
      headers: myHeaders,
    });
  }