import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import {
  getAllSubscribers,
  getSubscribers,
} from "../../services/Subscriber/SubscriberService";
import Pagination from "../common/Pagination";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../loader/Spinner";
import { CSVLink } from "react-csv";

export default function Subscriber(props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [listLenght, setListLenght] = useState("");
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [alldata, setAllData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [apiError, setApiError] = useState("");
  const [search, setSearch] = useState("");
  const limit = 10;
  const headers = [
    { label: "NAME", key: "name" },
    { label: "EMAIL", key: "email" },
    { label: "CITY", key: "city" },
    { label: "PHONE NUMBER", key: "fullPhoneNumber" },
  ];
  function getTableData() {
    setLoader(true);
    getSubscribers(currentPage, limit)
      .then((response) => {
        setData(response.data.data.subscribers);
        const total = response.data.data.count;
        setListLenght(response.data.data.count);
        setLoader(false);
        setPageCount(Math.ceil(total / limit));
        console.log(response.data, " table data ");
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 401) {
          localStorage.clear("token");
          props.history.push("/");
        }
      });
  }
  useEffect(() => {
    getTableData();
    getAllSubscribers(currentPage)
      .then((response) => {
        setAllData(response.data.data.subscribers);
        console.log(response.data, " all data");
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        if (error.response.data.statusCode === 401) {
          localStorage.clear("token");
          props.history.push("/");
        }
      });
  }, [currentPage]);
  return (
    <div>
      {" "}
      <PageTitle activeMenu="Subscribers List" motherMenu="Subscribers" />
      <Col>
        <Card>
          <Card.Header>
            <div className="text-right">
              <CSVLink
                filename={"Subscribers.csv"}
                data={alldata}
                headers={headers}
              >
                <button
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#F34F4F",
                    borderColor: "#F34F4F",
                  }}
                >
                  <span className="pr-2">Export</span>

                  <i class="flaticon-381-send"></i>
                </button>
              </CSVLink>
            </div>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>EMAIL</strong>
                  </th>
                  <th>
                    <strong>CITY</strong>
                  </th>
                  <th>
                    <strong>PHONE NUMBER</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, i) => (
                  <tr key={i}>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.city}</td>
                    <td>{item.fullPhoneNumber}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {data?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            <div className="card-footer clearfix">
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLenght > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLenght}{" "}
                  of {listLenght} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}
    </div>
  );
}
