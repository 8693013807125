import instance from "../Instance";
import {
  BLOCK_USERS,
  DELETE_USERS,
  GET_USERS,
  GET_USER_DETAILS,
  VERIFY_USER_DETAILS,
} from "./UserEndPoints";

export function getAllUsers(currentPage, limit, search) {
  console.log(search, " search");
  const data = localStorage.getItem("token");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    GET_USERS + `?page=${currentPage}&limit=${limit}&search=${search}`,
    {
      headers: myHeaders,
    }
  );
}

export function deleteUser(userId) {
  const data = localStorage.getItem("token");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.delete(DELETE_USERS + `?userId=${userId}`, {
    headers: myHeaders,
  });
}

export function blockUser(userId,toggle) {
  const data = localStorage.getItem("token");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData ={
    userId,toggle
  }
  return instance.post(BLOCK_USERS , postData,{
    headers: myHeaders,
  });
}

//user details

export function getOneUser(userId) {

  const data = localStorage.getItem("token");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(GET_USER_DETAILS + `?userId=${userId}`, {
    headers: myHeaders,
  });
}


export function verifyUserDetails(userId, type,response) {
  const postData = {
    userId,
    type,
    response,
    
  };
  const data = localStorage.getItem("token");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.post(VERIFY_USER_DETAILS, postData, {
    headers: myHeaders,
  });
}
