import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { reviewSubmit } from "../../services/AuthService";
import GroupButton from "../components/GroupButton/GroupButton";
import Header from "../components/GroupButton/Header";

export default function ReviewForm(props) {
  const params = new URLSearchParams(window.location.search);
  const id = params.get("id");
  const name = params.get("name");
  const email = params.get("email");
  console.log(id, name, email,"url");

  let errorsObj = { onTime: "", safe: "",respectful: "", boundaries: "" ,friends: ""};
  const [errors, setErrors] = useState(errorsObj);
  const [onTime, setOnTime] = useState("");
  const [safe, setSafe] = useState("");
  const [respectful, setRespectful] = useState("");
  const [boundaries, setBoundaries] = useState("");
  const [friends, setFriends] = useState("");
 const [apiError, setApiError] = useState();

  const selectedOption = props.option;
// console.log(selectedOption,"selected option")

console.log(onTime,safe,respectful,boundaries,friends," after data set")
// function onSetStateChange(data){
//   console.log(data,"new");
//   setOnTime({...onTime});
//   // setRespectful("");
//   // setSafe("");
//   // setBoundaries("");
//   // setFriends("");
// }

  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (onTime === "") {
      errorObj.onTime = "Please select one option.";
      error = true;
    }
    if (safe === "") {
      errorObj.safe = "Please select one option.";
      error = true;
    }
    if (respectful === "") {
      errorObj.respectful = "Please select one option.";
      error = true;
    }
    if (boundaries === "") {
      errorObj.boundaries = "Please select one option.";
      error = true;
    } 
    if (friends === "") {
      errorObj.friends = "Please select one option.";
      error = true;
    }
   
    setErrors(errorObj);
    if (error) {
      return;
    }
    reviewSubmit(id,onTime,safe,respectful,boundaries,friends,email)
      .then((response) => {
        props.history.push("/review-form-submit");
        console.log(response,"success")
      })
      .catch((error) => {
        console.log(error.response.data, " error");
        setApiError(error.response.data.message);
      });
  }
  return (
    <div className="">
      <Header />
      <div className="row m-0">
        <div className="col-md-6 col-12 mx-auto padding-top">
          <div className=" p-4 border-none">
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <label className="mb-2 "> {name} is/was on time</label>
                <GroupButton option={setOnTime}/>
                {errors.onTime && (
                    <div className="text-danger fs-12">{errors.onTime}</div>
                  )}
              </div>
              <div className="form-group">
                <label className="mb-2 mt-3">
                  {name} was polite is/was respectful
                </label>
                <GroupButton option={setRespectful}/>
                {errors.respectful && (
                    <div className="text-danger fs-12">{errors.respectful}</div>
                  )}
              </div>
              <div className="form-group">
                <label className="mb-2 mt-3 ">
                  {" "}
                  Already friends/I would consider being “friends” with them
                </label>
                <GroupButton option={setFriends}/>
                {errors.friends && (
                    <div className="text-danger fs-12">{errors.friends}</div>
                  )}
              </div>
              <div className="form-group">
                <label className="mb-2 mt-3"> I feel safe around {name}</label>
                <GroupButton option={setSafe}/>
                {errors.safe && (
                    <div className="text-danger fs-12">{errors.safe}</div>
                  )}
              </div>
              <div className="form-group">
                <label className="mb-2 mt-3">
                  {" "}
                  {name} respected my boundries
                </label>
                <GroupButton option={setBoundaries}/>
                {errors.boundaries && (
                    <div className="text-danger fs-12">{errors.boundaries}</div>
                  )}
              </div>
              <div className="form-group mt-4">
                <button
                  // onClick={() => props.history.push("/review-form-submit")}
                  type="submit"
                  className="btn w-100 colored-btn"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

