import React, { useState } from "react";
import rightIcon from "../../../images/icon.svg";
import colorRightIcon from "../../../images/icon (1).svg";

import { useDispatch } from "react-redux";
export default function GroupButton({option}) {
const [select, setSelect] = useState("");
// let select = "";
console.log(select,"hello")
  function onChange(data){
    setSelect(data);
    if(data){
      // dispatch(setSelectedOptionAction(data));
      option(data);
      
    }
  }
  
  return (
    <div>
      <div className="d-flex flex-column " style={{gap: "2rem"}}>
        <div className="d-flex justify-content-between align-items-center " style={{gap:"2rem"}}>
          <button type="button" className="btn color-btn flex-grow-1" onClick={()=>onChange("agree")}>Agree</button>
          {select === "agree"?<img src={colorRightIcon} className=""/>:<img src={rightIcon} />}
          
        </div>
        <div className="d-flex justify-content-between align-items-center " style={{gap:"2rem"}}>
          <button type="button" className="btn color-btn flex-grow-1" onClick={()=>onChange("neutral")}>Neutral</button>
          {select === "neutral"?<img src={colorRightIcon} className=""/>:<img src={rightIcon} />}
        </div>
        <div className="d-flex justify-content-between align-items-center " style={{gap:"2rem"}}>
          <button type="button" className="btn color-btn flex-grow-1" onClick={()=>onChange("disagree")}>Disagree</button>
          {select === "disagree"?<img src={colorRightIcon} className=""/>:<img src={rightIcon} />}
        </div>
      </div>
    </div>
  );
}
