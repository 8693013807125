import { SET_CURRENT_PAGE_ACTION } from "../actions/CurrentPageAction";


const initialState = {
    currentData: "",
};

export default function CurrentPageReducer(state = initialState, action) {
    
    if (action.type === SET_CURRENT_PAGE_ACTION) {
        return {
            ...state,
            currentData: action.payload,
        };
    }
    return state;
}
