import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import { LOGIN, LOGOUT, SUBMIT_REVIEW } from "./apiEndPoints/ApiEndPoints";
import instance from "./Instance";

export function signUp(email, password) {
  //axios call

  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
  };

  return instance.post(LOGIN, postData);
}
export function logOut() {
  const data = localStorage.getItem("token");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
 
  return instance.post(
    LOGOUT,
    { test: "" },
    {
      headers: myHeaders,
    }
  );
}
export function resetPassword(oldPassword, newPassword) {
  console.log(oldPassword, newPassword, "  in api function");
  const postData = {
    oldPassword,
    newPassword,
  };
  const data = localStorage.getItem("userDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  console.log(myHeaders, " headers response");
  return axios.put(`http://3.234.34.221:5000/admin/changePassword`, postData, {
    headers: myHeaders,
  });
}




export function reviewSubmit(id,
  onTime,safe,respectful,boundaries,friends,email) {
  const data = localStorage.getItem("userDetails");
  const postData = {
    id,
    onTime,safe,respectful,boundaries,friends,email
  };
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };

  console.log(postData, " post data");
  return instance.post(SUBMIT_REVIEW, postData, {
    headers: myHeaders,
  });
}
export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";

    default:
      return "";
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  //   tokenDetails.expireDate = new Date(
  //     new Date().getTime() + tokenDetails.expiresIn * 1000
  //   );
  // localStorage.setItem("userId", userId);
  localStorage.setItem("token", tokenDetails);
}
export function saveIdInLocalStorage(userId) {
  localStorage.setItem("userId", userId);
}
export function saveHostIdInLocalStorage(hostId) {
  localStorage.setItem("hostId", hostId);
}
export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  // const tokenDetailsString = localStorage.getItem("userDetails");
  // let tokenDetails = "";
  // if (!tokenDetailsString) {
  //   dispatch(logout(history));
  //   return;
  // }
  // tokenDetails = JSON.parse(tokenDetailsString);
  // let expireDate = new Date(tokenDetails.expireDate);
  // let todaysDate = new Date();
  // if (todaysDate > expireDate) {
  //   dispatch(logout(history));
  //   return;
  // }
  // dispatch(loginConfirmedAction(tokenDetails));
  // const timer = expireDate.getTime() - todaysDate.getTime();
  // runLogoutTimer(dispatch, timer, history);
}
