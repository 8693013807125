import React from "react";

export default function ReviewSubmit() {
  return (
    <div className="row align-items-center height">
      <div className=" col-md-6 col-12 mx-auto">
        <div className="p-4 border-none text-center">
          <h1 className="heading mb-3"> Review Submitted</h1>
          <p className="sub-heading">
            Sign up with HART today and receive a free 4 Month Membership
          </p>
          <div className="form-group mt-4">
            <button type="submit" className="btn btn-primary w-100 colored-btn">
              Install HART
            </button>
          </div>
        </div>
        <h1 className="footer-content text-center">Copy Invite Link</h1>
      </div>
      {/* <footer>
        <h1 className="footer-content text-center">Copy Invite Link</h1>
      </footer> */}
    </div>
  );
}
